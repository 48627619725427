import { Controller } from "stimulus"
import Cleave from "cleave.js";

export default class extends Controller {
    static targets = [ 'state', 'stateInput' ]

    connect() {

    }

    copy_from_insured(e) {
        e.preventDefault();

        const fields = [
            'first_name',
            'last_name',
            'middle_name',
            'common_name',
            'address',
            'city',
            'state',
            'zip',
            'birthday',
            'phone_cell',
            'phone_home',
            'phone_office',
            'email',
            'prefer_contact'
        ];
        const ins_prefix = 'client_insured_attributes_';
        const own_prefix = 'client_owner_attributes_';
        fields.forEach(function (fname) {
            const value = $('#' + ins_prefix + fname).val();
            $('#' + own_prefix + fname).val(value);
        });
        // get state
        const state_sel = $('#client_insured_attributes_state');
        const state_val = state_sel.val();
        const state_owner_sel = $('#client_owner_attributes_state');
        const inp_owner = $('#client_owner_attributes_state_other');
        if (state_val === $('option:last-child', state_sel).val()) {
            $('option:last-child', state_owner_sel).attr('value', state_val);
            state_owner_sel.val(state_val);
            inp_owner.val(state_val);
            inp_owner.show();
        } else {
            inp_owner.val('');
            inp_owner.hide();
        }


        // get gender
        const value = $('input[name="client[insured_attributes][gender]"]:checked').val();
        $('input[name="client[owner_attributes][gender]"][value='+value+']').prop('checked', true);
        // update flatpickr
        const fp = document.querySelector('#client_owner_attributes_birthday')._flatpickr;
        const bdate = $('#client_insured_attributes_birthday').val();
        fp.setDate(bdate);
    }

    next(e) {
        e.preventDefault();

        $('.alert.alert-success').remove();

        var next = $(e.target).data('next');
        $('#'+next+'-tab').tab('show').off('shown.bs.tab').on('shown.bs.tab', function () {
            $('html, body').animate({ scrollTop: 0 }, 400);
        });
    }
}
