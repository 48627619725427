import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "editor", 'spinner' ]

    connect() {
        $(this.spinnerTarget).show();
        $(this.editorTarget).hide();
        this.timer = setInterval(() => {
            if (typeof tinymce !== 'undefined') {
                clearInterval(this.timer);
                $(this.spinnerTarget).hide();
                $(this.editorTarget).show();
                tinymce.remove();
                tinymce.init({
                    selector: 'textarea#letter_content',
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist lists preview',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime paste code'
                    ],
                    toolbar: 'undo redo | bold italic underline strikethrough | forecolor backcolor | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | removeformat | fullscreen  preview code',
                    content_css: this.data.get('css')
                });
            }
        }, 200);
    }
}
