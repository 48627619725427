import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "select", "text" ]

    connect() {
        let sel = $(this.selectTarget);
        let inp = $(this.textTarget);
        let other_val = this.data.get('other');
        let last_op = $('option:last-child', sel)
        this.last_val = last_op.val();

        if (other_val !== '') {
            last_op.attr('value', other_val);
            sel.val(other_val);
            this.last_val = other_val;
            inp.val(other_val);
            inp.show();
        } else {
            inp.hide();
        }

        sel.on('change', (e)=>{
            let self = $(e.target);
            //this.last_val = $("option:last-child", self).val();
            if (self.val() === this.last_val) {
                inp.show();
            } else {
                inp.hide();
            }
        });

        inp.on('change', (e)=>{
            let self = $(e.target);
            let val = self.val();
            last_op.attr('value', val);
            this.last_val = val;
        });
    }
}