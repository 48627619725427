import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "total", "earnAmount" ]

    connect() {
        let total = 0.00;
        this.earnAmountTargets.forEach((element)=>{
            total += parseFloat(element.innerHTML);
        });

        this.totalTarget.innerHTML = '$' + total.toFixed(2);
    }
}
