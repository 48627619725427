import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "content", "trigger" ]

    connect() {
        var state = $(this.triggerTarget).prop('checked');
        if (state === false) {
            $(this.contentTarget).hide();
        }
    }

    toggle(e) {
        var state = $(e.target).val();
        if (state === 'true') {
            $(this.contentTarget).slideDown('slow');
        } else {
            $(this.contentTarget).slideUp('slow');
        }
    }
}
