import { Controller } from "stimulus"

export default class extends Controller {
    //static targets = []

    connect() {
        var searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has('tab')) {
            var param = searchParams.get('tab');
            $('#'+param+'-tab').tab('show');
        }
    }
}
