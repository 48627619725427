import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "states", "agents", "companies", "products" ]

    connect() {
        $(this.statesTargets).hide();
        $(this.agentsTargets).hide();
        $(this.companiesTargets).hide();
        $(this.productsTargets).hide();
    }

    toggle(e) {
        e.preventDefault();
        var self = $(e.target);
        $(this[self.data('name')+'Targets']).toggle('fast');
    }
}
