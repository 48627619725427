import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "firstName", "lastName", "middleName", "uniCode", "label",
        "btnRemove", "btnUpdate"
    ]

    connect() {
        // this.labelTarget.classList.add('d-none')
        $(this.labelTarget).hide()
        this.btnRemoveTarget.addEventListener('click', ()=>{
            if (window.confirm('Are you sure?')) {
                this.btnUpdateTarget.disabled = true
                this.btnUpdateTarget.innerHTML = '...'
                this.btnRemoveTarget.disabled = true

                this.firstNameTarget.value = ''
                this.lastNameTarget.value = ''
                this.middleNameTarget.value = ''
                this.uniCodeTarget.value = ''
                $.ajax({
                    url: '/users/update_co_leader',
                    method: 'post',
                    data: {
                        user: {
                            agent_info_attributes: {
                                co_leader: false,
                                co_first_name: '',
                                co_last_name: '',
                                co_middle_name: '',
                                co_uni_code: '',
                            }
                        }
                    }
                }).done(()=>{
                    this.btnUpdateTarget.disabled = false
                    this.btnUpdateTarget.innerHTML = 'Update'
                    this.btnRemoveTarget.disabled = false

                    // this.labelTarget.classList.remove('d-none')
                    $(this.labelTarget).fadeIn()
                    setTimeout(()=>{
                        // this.labelTarget.classList.add('d-none')
                        $(this.labelTarget).fadeOut()
                    }, 1800)
                })
            }
        })

        this.btnUpdateTarget.addEventListener('click', ()=>{
            this.btnUpdateTarget.disabled = true
            this.btnUpdateTarget.innerHTML = '...'
            this.btnRemoveTarget.disabled = true
            $.ajax({
                url: '/users/update_co_leader',
                method: 'post',
                data: {
                    user: {
                        agent_info_attributes: {
                            co_leader: true,
                            co_first_name: this.firstNameTarget.value,
                            co_last_name: this.lastNameTarget.value,
                            co_middle_name: this.middleNameTarget.value,
                            co_uni_code: this.uniCodeTarget.value,
                        }
                    }
                }
            }).done(()=>{
                this.btnUpdateTarget.disabled = false
                this.btnUpdateTarget.innerHTML = 'Update'
                this.btnRemoveTarget.disabled = false

                // this.labelTarget.classList.remove('d-none')
                $(this.labelTarget).fadeIn()
                setTimeout(()=>{
                    // this.labelTarget.classList.add('d-none')
                    $(this.labelTarget).fadeOut()
                }, 1800)
            })
            // this.firstNameTarget.value = ''
            // this.lastNameTarget.value = ''
            // this.middleNameTarget.value = ''
            // this.uniCodeTarget.value = ''
        })
    }
}