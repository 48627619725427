import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "noti" ]

    connect() {
        //this.notiTarget.textContent = 'Hello, Stimulus!'
        //alert(this.notiTargets.length);
    }

    show_note(e) {
        var nid = $(e.target).data('nid');
        var targ = this.notiTargets[nid];
        //alert(targ)
        $(targ).addClass('border-primary');
        $('html, body').animate({ scrollTop: $(targ).offset().top - 51 }, 'slow');
        setTimeout(function () {
            $(targ).removeClass('border-primary');
        }, 1600);
    }
}