import { Controller } from "stimulus"
import Cleave from "cleave.js";

export default class extends Controller {
    static targets = []

    connect() {
        // cleave fields
        $('.input-phone').toArray().forEach(function (field) {
            new Cleave(field, {
                phone: true,
                phoneRegionCode: 'us'
            });
        });

        $('.zip-code').toArray().forEach(function (field) {
            new Cleave(field, {
                numericOnly: true,
                delimiter: ' ',
                blocks: [5,4]
            });
        });
    }

}