import { Controller } from "stimulus"

export default class extends Controller {
    static targets = []

    connect() {
        const status = JSON.parse(this.data.get('status'));

        this.prepare_form(status)
    }

    prepare_form(status) {
        $("#client_product_files").bind("change", function() {
            let size_in_megabytes = this.files[0].size/1024/1024;
            if (size_in_megabytes > 30) {
                alert("Maximum file size is 30MB. Please choose a smaller file.");
                $("#client_product_files").val("");
            }
        });

        // product type show/hide
        var ptype_input = $('input[name="client[product_attributes][product_type]"]');

        if (status.ptype !== 'Annuity') $('#plan_div').hide();

        ptype_input.on('change', function (e) {
            var value = $(this).val();
            if (value === 'Annuity') {
                $('#plan_div').slideDown('slow');
            } else {
                $('#plan_div').slideUp('slow');
            }
        });

        // flexible premium show/hide
        var fprm_input = $('#client_product_attributes_prm_flexible');

        if (status.flex !== true) $('#fprm_div').hide();

        fprm_input.on('change', function (e) {
            var value = $(this).prop('checked');
            if (value == true) {
                $('#fprm_div').slideDown('slow');
            } else {
                $('#fprm_div').slideUp('slow');
            }
        });

        // premium div show/hide
        var single_input = $('#client_product_attributes_prm_single');
        var roll_input = $('#client_product_attributes_prm_roll');
        var transfer_input = $('#client_product_attributes_prm_transfer');

        if (status.single !== true) $('#premium_single_div').hide();
        if (status.roll !== true) $('#premium_roll_div').hide();
        if (status.transfer !== true) $('#premium_transfer_div').hide();

        var check_toggle = function (e) {
            var self = $(this);
            var value = self.prop('checked');
            var target = self.data('target');
            if (value === true) {
                $('#premium_'+target+'_div').show(500);
            } else {
                $('#premium_'+target+'_div').hide(400);
            }
        }

        single_input.on('change', check_toggle);
        roll_input.on('change', check_toggle);
        transfer_input.on('change', check_toggle);

        // lump sum show/hide
        var lump_input = $('input[name="client[product_attributes][lump_sum_type]"]');

        if (status.ltype === 'No') $('#lump_div').hide();

        lump_input.on('change', function (e) {
            var value = $(this).val();
            if (value !== 'No') {
                $('#lump_div').slideDown('slow');
            } else {
                $('#lump_div').slideUp('slow');
            }
        });
    }
}