import bsCustomFileInput from 'bs-custom-file-input'
import Cleave from 'cleave.js';
import Choices from "choices.js";

let idle_timer = 0;

const fpickers = [];
const choices = [];

$(document).on('turbolinks:load', function(){

    // $(".datepicker").flatpickr({
    //     allowInput: true,
    //     altInput: true,
    //     altFormat: "F j, Y",
    //     dateFormat: "Y-m-d"
    // });

    const pickers = document.querySelectorAll('.datepicker');
    if (pickers.length > 0) {
        pickers.forEach((picker)=>{
            const fp = flatpickr(picker, {
                allowInput: true,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            });
            fpickers.push(fp);
        });
    }

    // $(".timepicker").flatpickr({
    //     allowInput: true,
    //     enableTime: true,
    //     noCalendar: true,
    //     dateFormat: "H:i"
    // });

    const tpickers = document.querySelectorAll('.timepicker');
    if (tpickers.length > 0) {
        tpickers.forEach((picker)=>{
            const fp = flatpickr(picker, {
                allowInput: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i"
            });
            fpickers.push(fp);
        });
    }

    // $(".notedatepicker").flatpickr({
    //     allowInput: true,
    //     enableTime: true,
    //     altInput: true,
    //     altFormat: "F j, Y h:iK",
    //     dateFormat: "Z"
    // });

    // bootstrap file input
    bsCustomFileInput.init();

    // choices.js
    // const element = document.querySelector('.sel2');
    // if (element !== null) {
    //     const choices = new Choices(element, { removeItemButton: true });
    // }

    const elements = document.querySelectorAll('.sel2');
    if (elements.length > 0) {
        elements.forEach((element)=>{
            let choice = new Choices(element, { removeItemButton: true });
            choices.push(choice);
        });
    }

    // get current URL path and assign 'active' class
    let pathname = window.location.pathname;
    $('#main_nav > li > a[href="'+pathname+'"]').parent().addClass('active');

    // logout user after idle for 20 minutes
    if ($('#noti_count').length > 0 && $('#navAdminLink').length === 0) {
        if (idle_timer > 0) clearTimeout(idle_timer);

        idle_timer = setTimeout(()=>{
            window.location.href = '/logout';
        }, 1200000);
    }
});

document.addEventListener("turbolinks:before-cache", function() {
    fpickers.forEach((fp)=>{
        fp.destroy();
    });

    choices.forEach((element)=>{
        element.destroy();
    });
});